import styled, { css } from 'styled-components'
import {
  BTN_BLACK_COLOR,
  PRIMARY_COLOR,
  svgFillSelector,
  svgStrokeSelector,
  TEXT_BLACK_COLOR,
  ClickButtonProps,
} from '@edwin-edu/ui-web-components'
import { FormattedClickButton } from './Locale/FormattedUIComponents'
// todo: change imports in app, instead of re-export here
export {
  ButtonBlack as Button,
  RoundButton,
  ActiveButton,
} from '@edwin-edu/ui-web-components'

/**
 * Overrides the colors for the FormattedClickButton, which is used on the site a lot with the new theme
 */
export const StyledFormattedClickButton = styled(FormattedClickButton)<
  ClickButtonProps & { active?: boolean }
>`
  ${(props) =>
    !props.white &&
    css`
      ${svgStrokeSelector} {
        stroke: ${BTN_BLACK_COLOR};
      }

      ${svgFillSelector} {
        fill: ${BTN_BLACK_COLOR};
      }

      :hover {
        ${svgStrokeSelector} {
          stroke: ${TEXT_BLACK_COLOR} !important;
        }

        ${svgFillSelector} {
          fill: ${TEXT_BLACK_COLOR} !important;
        }
      }
    `}

  ${(props) =>
    props.active &&
    css`
      ${svgStrokeSelector} {
        stroke: ${PRIMARY_COLOR};
      }
      ${svgFillSelector} {
        fill: ${PRIMARY_COLOR};
      }
      :hover {
        ${svgStrokeSelector} {
          stroke: ${PRIMARY_COLOR} !important;
        }

        ${svgFillSelector} {
          fill: ${PRIMARY_COLOR} !important;
        }
      }
    `}
`
