module.exports = {
  messages: {
    Action: 'Action',
    Admin: 'Admin',
    All: 'Tout',
    Available: 'Disponible',
    avg: 'Moy.',
    approve: 'Approuver',
    at: 'à',
    Browse: 'parcourir',
    created: 'créé',
    yes: 'Oui',
    Edit: 'modifier',
    yesterday: 'Hier',
    your: 'votre',
    Your: 'Votre',
    back: 'Retour d’ccueil',
    Back: 'Retour',
    backHome: 'retour',
    goBack: 'Retourner',
    cancel: 'annuler',
    Cancel: 'Annuler',
    Copy: 'Copie',
    clear: 'Effacer',
    confirm: 'confirmer',
    close: 'fermer',
    Close: 'Fermer',
    Continue: 'Continuer',
    Covered: 'Couvert',
    Deleted: 'Supprimé!',
    description: 'Description',
    done: 'done',
    duplicate: 'Dupliquer',
    enterName: 'Entrer un nom',
    error: 'Erreur',
    Find: 'Trouve',
    unknown_error: 'Une erreur inconnue s’est produite',
    no: 'Non',
    grade: 'année',
    Grade: 'Année',
    home: 'Accueil',
    Information: 'Information',
    Invited: 'Invité',
    join: 's’inscrire',
    Label: 'étiquette',
    learningObject: 'Objet d’pprentissaget',
    Loading: 'Chargement',
    location: 'emplacement',
    Location: 'Emplacement',
    member: 'Membre',
    members: 'Membres',
    mySubjects: 'Mes Sujets',
    Name: 'Nom',
    or: 'ou',
    Other: 'Autre',
    next: 'suivant',
    note: 'Note',
    notes: 'Notes',
    text: 'Text',
    on: 'activé',
    off: 'désactivé',
    tryAgain: 'Essayer à nouveau',
    remove: 'Retirer',
    reset: 'réinitialiser',
    Resources: 'Ressources',
    role: 'rôle',
    save: 'sauvegarder',
    Save: 'sauvegarder',
    Saved: 'Enregistrée!',
    Saving: 'Économie…',
    Search: 'Recherche',
    Select: 'Sélectionner',
    send: 'envoyer',
    sending: 'envoi en cours',
    shareToGoogleClassroom: 'partager sur Google Classroom',
    shareToMicrosoftTeams: 'partager avec Microsoft Teams',
    signOut: 'déconnexion',
    saving_Capitalized: 'Sauvegarde…',
    saved_Capitalized: 'Sauvegardé!',
    skip: 'sauter',
    skip_to_main_content: 'Passer au contenu principal',
    spin: 'tourner!',
    start: 'commencer',
    Student: 'Élève',
    Students: 'Élèves',
    subject: 'sujet',
    subjects: 'sujets',
    Subject: 'Sujet',
    Subjects: 'Sujets',
    allSubjects: 'Tous les sujets',
    Submit: 'Soumettre',
    Submitting: 'Soumission...',
    tags: 'Mots-clés ',
    Teacher: 'Enseignant',
    teachers: 'Enseignants',
    teacherOnly: 'Personnel enseignant seulement',
    teachingNotes: 'Notes pédagogiques',
    Term: 'Durée',
    to: 'à',
    today: 'Aujourd’ui',
    Upload: 'Télécharger',
    Uploading: 'Téléchargement...',
    user: 'utilisateur',
    users: 'utilisateurs',
    selected: 'sélectionnés',
    assign: 'assigner',
    copy: 'copier',
    refresh: 'actualiser',
    Auth_invalidInfo: 'Veuillez vérifier votre email ou votre mot de passe',
    Auth_tooManyRequests:
      'Trop de tentatives de connexion. Veuillez réessayer plus tard',
    Auth_privacy_policy: 'Politique de confidentialité',
    Auth_terms_and_conditions: 'Termes et conditions',
    Breadcrumbs_home: 'Accueil',
    Breadcrumbs_searchResults: 'Résultats de recherche',
    Breadcrumbs_reader: 'Lecteur',
    Breadcrumbs_collection: 'Collection',
    Breadcrumbs_collections: 'Collections',
    Breadcrumbs_browseCollections: 'Collections d’Edwin',
    Breadcrumbs_student: 'Élève',
    Breadcrumbs_course: 'Cours',
    Breadcrumbs_curriculum: 'Programme d’tudes',
    Breadcrumbs_curriculumResults: 'Résultats du programme',
    Collection_addItems: 'Ajouter des éléments',
    Collection_item: 'Article',
    Collection_items: 'Articles',
    Collection_addFile: 'Ajouter un fichier',
    Collection_addItemsModal1:
      'Ajouter des objets d’apprentissage à votre collection',
    Collection_addItemsModal2: 'à l’aide de',
    Collection_addItemsModal3: 'icône de la page Résultats de la bibliothèque',
    Collection_addLink: 'Ajouter un hyperlien',
    Collection_addItemsModal6:
      'Ajouter tout matériel accessible par hyperlien (URL), y compris des vidéos, des pages Web et des documents partagés.',
    Collection_addItemsModal7:
      'Sont acceptés les fichiers PDF, Text, EPUB, Word, PowerPoint, Excel, JPG ou PNG d’une taille inférieure à 50 Mo.',
    Collection_addItemsModalOnline: 'Vous devez être en ligne.',
    Collection_addTitle: 'ajouter un titre',
    Collection_enterName: '| Entrez le nom de votre collection',
    Collection_addDescription: 'ajouter une description',
    Collection_noSubject: 'Pas de sujet',
    Collection_noSubjectCreate: 'Sélectionnez le(s) sujet(s)',
    CollectionAddLinkModal_UrlLink: 'adresse URL*',
    CollectionAddLinkModal_invalidLink: 'URL invalide',
    Collection_selectMultiple: 'Sélectionnez',
    Collection_deleteSelected: 'Supprimer sélectionnée',
    Collection_bulkDelete_confirmMessage:
      'Êtes-vous sûr de vouloir supprimer définitivement ',
    Collection_EditContent: 'Modifier le contenu',
    CollectionAddLinkModal_requiredField: '* Champ obligatoire',
    CollectionAddLinkModal_uploadMsg:
      'Le téléchargement peut durer jusqu’à 2 minutes selon la vitesse de votre connexion…',
    CollectionsPage_title: 'Vos Collections',
    CollectionsPage_titleReadOnly: 'Edwin Collections',
    CollectionCard_assessment: 'Évaluation',
    CollectionCard_addAssessment: 'Ajouter une évaluation',
    CollectionCard_addCurriculum: 'Ajouter un programme d’études',
    CollectionCard_addDescription: 'Ajouter une description',
    CollectionCard_addDescriptionCreate:
      'Décrivez de quoi parle cette collection',
    CollectionCard_addSkillsCompetencies: 'Ajouter des compétences',
    CollectionCard_addTeachingNotes: 'Ajouter des notes pédagogiques',
    CollectionCard_curriculum: 'Programme d’études',
    CollectionCard_skillsCompetencies: 'Habiletés ou compétences',
    CollectionCard_createSelectTags: 'Créer ou sélectionner des mots-clés',
    CollectionCard_selectTagsCreate: 'Sélectionner ou créer des balises',
    CollectionCard_lastUpdated: 'Dernière modification :',
    CollectionCard_addTags:
      'Ajouter des mots-clés pour raffiner votre recherche',
    CollectionCard_edited: 'Modifié',
    CollectionDetail_cantFind:
      'Désolé, nous ne pouvons pas trouver une collection avec cet identifiant',
    CollectionCard_forStudents: 'pour les élèves',
    CollectionDetail_copyName: 'nouveau nom de collection',
    CollectionDetail_copyingCollection: 'Copie dans vos collections',
    CollectionDetail_lastModified: 'Dernière modification :',
    CollectionDetail_removeCollection: 'supprimer de mes collections',
    CollectionDetail_viewCollections: 'voir mes collections',
    CollectionDetail_copy: 'Copiez dans Vos Collections',
    CollectionDetail_createNewCopy: 'créer une nouvelle copie',
    CollectionDetail_goToCopy: 'ouvrir une copie existante',
    CollectionsFilterDropDown_allCollections: 'Toutes les collections',
    CollectionLinkItemRow_enterURL: 'Entrez l’RL (www.nelson.com)',
    CollectionLinkItemRow_enterTitle: 'Entrez le titre',
    CollectionLinkItemRow_enterDescription: 'Entrez une description',
    CollectionsTagInput_selectTags: 'Sélectionner des mots-clés',
    CreateCollectionCard_header: 'Nouvelle collection',
    CreateCollectionCard_name: 'Nom',
    // Dashboard
    DashboardPage_TitleDescription:
      'Données d’uto vérification en un seul endroit, mises à jour en temps réel.',
    DashboardPage_createDashboard: 'Créer un nouveau tableau de bord',
    DashboardPage_Dashboard: 'Tableau de bord',
    DashboardPage_nameInput: 'Tableau de bord nom',
    DashboardPage_information: 'Informations du tableau de bord',
    DashboardPage_descriptionInput: 'Brève description',
    DashboardPage_selectedSubject: 'Choisir des sujets pour',
    DashboardPage_subjectGuide:
      'Pour une vue plus ciblée, sélectionnez les sujets pertinents pour ce tableau de bord. Cela vous permettra de visualiser les progrès des élèves exclusivement liés à ces matières particulières dans le tableau de bord.',
    DashboardPage_editLater:
      'Vous pourrez modifier ces informations ultérieurement.',
    DashboardPage_deleteClass: 'Supprimer la tableau de bord',
    DashboardPage_classOrGroupName:
      'Quel est le nom de votre tableau de bord ou groupe',
    DashboardPage_joinArchivedClass:
      'Oups ! Ce tableau de bord a été archivé ou n’existe pas. Veuillez réessayer.',
    DashboardPage_joinFullClass:
      'Cette classe à laquelle vous avez été invité a atteint sa limite de 100 personnes.',
    DashboardPage_requiredFieldIsEmpty:
      'Un champ obligatoire est vide ou invalide!',
    DashboardPage_nameLengthValidation:
      'Le nom est obligatoire (3 à 100 caractères)',
    DashboardPage_descriptionLengthValidation:
      'La description doit comprendre entre 3 et 255 caractères!',
    DashboardPage_notesLengthValidation:
      'Les notes doivent comprendre entre 3 et 2550 caractères!',
    DashboardPage_namePlaceholder: 'Ajouter un titre',
    DashboardPage_descriptionPlaceholder:
      'Ajouter une courte description de votre tableau de bord',
    DashboardPage_notesPlaceholder:
      'Des notes spécifiques sur ce tableau de bord que vous souhaitez partager? c.-à-d. programme, attentes, coordonnées',
    DashboardPage_Fetching_message:
      'Nous recueillons vos données. Cette opération peut durer jusqu’à 2 minutes selon la votre vitesse de votre connexion…',
    DashboardPage_archivedClasses: 'Tableaux de bord archivés',
    DashboardPage_title: 'Tableaux de bord',
    DashboardPage_alreadyJoined: 'Vous avez déjà rejoint ce cours',
    DashboardPage_joinClass:
      'Veuillez entrer votre code de classe pour rejoindre:',
    DashboardPage_archiveClass: 'Tableau de bord d’rchives',
    DashboardPage_joinPending:
      'Demande en attente. Vous aurez accès une fois que vous serez approuvé par un autre enseignant de la tableau de bord.',
    DashboardPage_joinProgress: 'Joindre…',
    DashboardPage_another: 'Essayez un autre code',
    DashboardPage_joinClassCode:
      'Entrez le code de tableau de bord à 8 caractères',
    DashboardPage_joinFooter:
      'Demandez à votre professeur un code de classe Edwin',
    DashboardPage_checkStudentProgress: 'Afficher les réponses des étudiants',
    DashboardPage_studentProgress: 'Réponses des étudiants',
    DashboardIndividualProgress: 'Réponses individuelles',
    DashboardMyProgress: 'Mes réponses',
    DashboardMyDashboards: 'Mes tableaux de bord',
    DashboardProgress: 'Réponses',
    DashboardMembers: 'Members',
    DashboardInfo: 'Information',
    DashboardCurriculumCoverage: `Couverture du Programme`,
    DashboardCurriculumObjective: 'Objectif du programme',
    DashboardPlannerInstruction: `Consultez et marquez les objectifs du programme pour chaque matière de votre bibliothèque et passez en revue ce que vous avez déjà couvert dans ce cours.`,
    DashboardResetWarning:
      'Cela supprimera toutes les coches de votre programme pour',
    DashboardResetConfirm: 'Êtes-vous sur de vouloir continuer?',
    DashboardNoSubjects: `Ce tableau de bord n'a pas encore de sujets`,
    DashboardSelectSubjects:
      'Si vous êtes le créateur du tableau de bord, accédez à la bibliothèque et sélectionnez vos matières pour accéder à la liste des programmes',
    DashboardOpenLibrary: 'Bibliothèque ouverte',
    DashboardEmptyClasses_teacherHeader: 'Créer des tableaux de bord',
    DashboardEmptyClasses_teacherDesc:
      'Il est temps d’mbarquer vos élèves avec Edwin! Configurez simplement le tableau de bord de votre classe et partagez le code de classe unique avec vos élèves.',
    DashboardEmptyClasses_teacherSubDesc:
      'Au fur et à mesure qu’ils apparaissent dans ce code, ils seront automatiquement approuvés et connectés directement au tableau de bord de votre classe. Très facile.',
    DashboardEmptyClasses_teacherLearnMore:
      'En savoir plus sur les tableaux de bord',
    DashboardEmptyClasses_studentHeader: 'Suivez vos progrès d’pprentissage',
    DashboardEmptyClasses_studentDesc:
      'Rejoindre un tableau de bord permet à votre professeur de voir vos progrès dans Edwin. Demandez un code à votre professeur et rejoignez un tableau de bord pour commencer',
    DashboardEmptyClasses_studentSubDesc1:
      'Visualisez votre progression dans Edwin en cliquant sur l’cône de l’nglet',
    DashboardEmptyClasses_studentSubDesc2: 'Mes réponses',
    DashboardEmptyClasses_studentSubDesc3: 'icône d’nglet en haut de la page',
    DashboardEmptyClasses_joinButton: 'Rejoindre un tableau de bord',
    DashboardEmptyClasses_createButton: 'Créez votre premier tableau de bord',
    DashboardEmptyProgress_studentDesc:
      'Salut, super élèves! En répondant aux questions d’uto{br}vérification dans vos ressources d’pprentissage Edwin,{br}vous pouvez facilement suivre votre progression et {br}ce que vous réalisez. De plus, vous verrez vos résultats{br}ici - c’est votre indicateur de progression personnel!',
    DashboardEmptyProgress_exploreButton: 'Explorez la bibliothèque',
    DashboardCreate: 'Créer un tableau de bord',
    Dashboard_ClassPage_ArchiveModal:
      'Voulez-vous vraiment déplacer ce tableau de bord vers vos tableaux de bord archivés?',
    Dashboard_ClassPage_ConfirmRemove:
      'Voulez-vous vraiment supprimer ces utilisateurs de votre tableau de bord?',
    Dashboard_ClassPage_DeleteClass:
      'Voulez-vous vraiment supprimer ce tableau de bord? Cette action est irréversible?',
    Dashboard_ClassPage_LeftClass:
      'Vous avez quitté ce tableau de bord avec succès',
    DashboardPage_restoreClass: 'Restaurer la tableau de bord',
    Dashboard_ClassPage_EmptyDescription:
      'Partagez une description de ce tableau de bord ici.',
    Dashboard_ClassPage_EmptyNotes:
      'Partagez des notes sur ce tableau de bord ici.',
    DashboardEmptyProgress_joinYourDashboard:
      'Invitez des personnes à rejoindre votre tableau de bord',
    Dashboard_ClassPage_EmptyInvite_Heading:
      'Invitez des personnes sur votre tableau de bord',
    Dashboard_ClassPage_EmptyInvite_Description:
      'Partagez le code de tableau de bord unique à 8 caractères avec les élèves et les enseignants afin qu’ils puissent rejoindre votre tableau de bord',
    Dashboard_ClassPage_RemoveAllTeachers_Warning:
      'Votre tableau de bord nécessite au moins un enseignant approuvé.',
    Dashboard_ClassPage_Removed_Message: `Vous n'avez pas accès à ce tableau de bord`,
    Dashboard_ClassPage_Removed_SubMessage:
      'Contactez votre administrateur et réessayez',
    DashboardCard_avgTimeLabel: 'Durée moy.',
    DashboardEmptyLO_header: 'Aucun résultat trouvé.',
    DashboardEmptyLO_subHead:
      'Veuillez élargir votre recherche en sélectionnant des dates et/ou des sujets différents.',
    DashboardEmptyLO_offline1: 'Vous avez mis fin à votre session',
    DashboardEmptyLO_offline2:
      'sans avoir récupéré les données demandées. Veuillez vous connecter et recommencer.',
    DashboardStudents_avgSelfChecks: 'Autoévaluations moy.',
    DashboardMySelfCheckedResponses: 'Mes réponses',
    DashboardLO_avg: 'Moy.',
    DashboardStudents_lastViewed: 'Dernière consultation',
    DashboardStudents_classDetail: 'Descriptif du tableau de bord',
    DashboardStudents_classDetailInfo:
      'Votre enseignant peut partager une description de ce tableau de bord avec vous ici.',
    DashboardStudents_classNotes: 'Remarques sur ce tableau de bord',
    DashboardStudents_classNotesInfo:
      'Votre enseignant peut partager avec vous des notes sur ce tableau de bord ici.',
    DashboardStudents_leaveClass: 'Quitter le tableau de bord ?',
    DashboardStudents_leaveClassConfirm:
      'Voulez-vous vraiment quitter le tableau de bord?',
    DashboardStudents_rejoinClass:
      'Vous pouvez rejoindre ce tableau de bord en utilisant le code unique à 8 caractères',
    DashboardStudents_selfChecksEntered: 'Autovévaluations Entrées',
    DashboardStudentsLOs_timeSpent: 'Temps passé',
    DashboardStudentsLOs_notAvailable:
      'Cet objet d’pprentissage n’est plus disponible',
    DashboardDate_7days: '7 derniers jours',
    DashboardDate_14days: '14 derniers jours',
    DashboardDate_30days: '30 derniers jours',
    DashboardDate_60days: '60 derniers jours',
    DashboardDate_90days: '90 derniers jours',
    DashboardDate_6months: '6 derniers mois',
    DashboardDate_9months: '9 derniers mois',
    DashboardDate_12months: '12 derniers mois',
    DashboardDate_AllTime: 'Toutes les années',
    DashboardClassCode_inviteStudents: 'Inviter des élèves',
    DashboardClassCode_infoText:
      'Partagez ce lien avec les élèves ou les enseignants pour leur permettre de rejoindre facilement ce tableau de bord de classe.',
    DashboardClassCode_classCode: 'Code de la classe:',
    DashboardClassCode_followLink: 'Suivez ce lien:',
    DashboardEmptyProgress_description:
      'partagez ce lien avec les élèves ou les enseignants{br}pour leur permettre de rejoindre facilement ce{br}tableau de bord de classe. ',
    DashboardEmptyProgress_description2:
      'Ce lien de partage ouvre le lien de participation et pré-remplit le code du cours pour eux.',
    DashboardEmptyProgress_description4:
      'Demandez-leur d’aller sur <strong>go.edwin.app/join</strong> et saisissez le code du cours pour rejoindre ce tableau de bord de cours.',
    DashboardEmptyProgress_learnMore:
      'En savoir plus sur Inviter des personnes',
    DashboardClassCode_descText1: 'Allez sur ',
    DashboardClassCode_descText2: 'go.edwin.app/join',
    DashboardClassCode_descText3:
      ' et entrez le code de classe ci-dessus pour rejoindre ce tableau de bord de classe.',
    DropZone_browseFiles: 'parcourir les fichiers',
    DropZone_instructions1:
      'Glisser-déposer votre fichier ici ou <span>parcourir</span> votre répertoire de fichiers.',
    DropZone_instructions2:
      'Sont acceptés les fichiers PDF, Text, EPUB, Word, PowerPoint, Excel<br></br>JPG ou PNG d’une taille inférieure à 50 Mo.',
    DropZone_error1:
      'Le fichier que vous tentez de joindre dépasse <br /> 50 Mo ou son format n’est pas accepté.',
    DropZone_error2:
      'Faire glisser un nouveau fichier ou <span>parcourir la liste</span> pour en joindre un.',
    DropZone_onlineRequired:
      'Vous devez être en ligne pour utiliser cette fonctionnalité.<br></br>Veuillez vérifier votre connexion Internet.',
    DropZone_uploadFailed:
      'Le téléchargement a échoué. Veuillez sélectionner un autre fichier ou réessayer plus tard.',
    EmptyState_createCollection: 'Aller à la bibliothèque',
    EmptyState_createFirstCollection: 'Créez votre première collection',
    EmptyStateDescription_Collections1:
      'Ajouter des objets d’apprentissage à votre collection<br></br>à l’aide de',
    EmptyStateDescription_Collections2:
      'l’icône de la page Résultats de la bibliothèque',
    EmptyStateDescription_LOs:
      'Commencer à ajouter des éléments à votre collection.<br></br>Vous pouvez ajouter des objets d’apprentissage, des fichiers et des hyperliens!',
    EmptyStateDescription_Notes:
      'Une fois que vous avez fini de prendre des notes ou de surligner des informations importantes, vous pouvez retrouver tout votre travail enregistré automatiquement sur cette page.',
    EmptyStateTitle_Collections: 'Aucune collection pour le moment',
    EmptyStateTitle_Notes: 'Vous n’avez pas encore de notes',
    EmptyStateTitle_LOs: 'Cette collection est vide.',
    AppErrorPage_applicationError: 'Erreur d’pplication',
    Google_info:
      'Pour ouvrir la fenêtre "Partager sur Google", appuyez sur : <Entrée> <Tab> <Entrée>',
    PageError_somethingWrong:
      'Quelque chose a mal tourné. Essayez de naviguer vers une autre page ou de recharger l’pplication ci-dessous.',
    LibraryError_linkError: 'Erreur de Lien',
    LibraryError_subjectMissing: 'Sujet Manquant',
    LibraryError_subjectMissingDesc:
      'Oups, le sujet que vous recherchez est introuvable dans vos paramètres.',
    MenuLink_fallback: 'Lien vers le menu principal',
    MenuButton_fallback: 'Bouton du menu principal',
    Notification_copied: 'Copié!',
    Notification_linkCopied: 'Hyperlien copié!',
    HomePage_edwinHome: 'Edwin Accueil',
    HomePage_exploreLibrary: 'Explorez la bibliothèque',
    HomePage_learningResources: '<span>Ressources</span> d’apprentissage',
    HomePage_resourcesDesc:
      'Retrouvez toutes vos ressources d’apprentissage dans la bibliothèque. Parcourez par sujet ou recherchez tout ce que vous aimeriez apprendre.',
    HomePage_interactiveTools: '<span>Outils</span> Interactifs',
    HomePage_mathToolsDesc:
      'Donnez vie à l’apprentissage avec ces outils interactifs.',
    HomePage_mathToolsButton: 'Tous les outils',
    HomePage_yourNotes: 'Notes',
    HomePage_yourNotesDesc:
      'Passez en revue les notes et les surlignages que vous avez pris en explorant les objets d’apprentissage.',
    HomePage_yourNotesButton: 'Toutes les remarques',
    HomePage_yourCollections: 'Collections',
    HomePage_yourCollectionsDesc:
      'Créez des collections pour organiser les ressources d’apprentissage de la bibliothèque ainsi que les liens et documents de vos sites Web préférés.',
    HomePage_yourCollectionsButton: 'Toutes les collections',
    HomePage_dashboards: '<span>Tableaux de</span> Bord',
    HomePage_dashboardsDesc:
      'Données d’auto-vérification réunies au même endroit, mises à jour en temps réel.',
    HomePage_dashboardsButton: 'Tous les tableaux de bord',
    HomePage_title: 'Collections en vedette',
    HomePage_needsUpdate: 'doit être mis à jour!',
    HomePage_versionUpdateButton: 'Allons-y!',
    RecentlyViewedPanel_header: '<span>Consulté</span> récemment',
    NotificationsDrawer_Title: 'Notifications',
    NotificationsDrawer_Alerts: 'Alertes',
    NotificationsDrawer_virtualSessions: 'Séances virtuelles',
    LearningObjectDetailModal_topic: 'Sujet',
    LearningObjectDetailModal_focus: 'Concentrer',
    LearningObjectDetailModal_subFocus: 'Sous-Concentrer',
    LearningObjectRow_collections: 'Collections',
    LearningObjectRow_info: 'Plus d’information',
    CollectionItem_edit: 'Modifier',
    CollectionItem_delete: 'Supprimer de la collection',
    // LibraryHome
    LibraryHome_title1: 'Bibliothèque',
    LibraryHome_title2: 'Accueil',
    LibraryHome_recent_subjects: 'Sujets Récemment Consultés',
    LibraryHome_clear_recents: 'Effacer les vues récemment',
    LibraryHome_featured: 'En vedette',
    Library_transform_description_1:
      'Choisissez une province et des niveaux scolaires pour trouver vos matières les plus pertinentes. Vous pourrez modifier ces choix ultérieurement en sélectionnant',
    Library_transform_description_2: 'l’cône dans votre liste de sujets.',
    Library_select_location: 'Sélectionnez votre emplacement',
    Library_save_preferences: 'Enregistrer mes préférences',
    Library_nonTransform_description:
      'licence vous permet de voir le contenu de vos préférés',
    Library_info_link: 'Cliquez <a>ici</a> pour plus d’nformations.',
    Library_days_left_none:
      'La période de 30 jours pour modifier vos paramètres est terminée',
    Library_days_left_1: 'Il vous reste',
    Library_days_left_2: 'jours pour modifier vos paramètres',
    Library_select_grades: 'Sélectionnez vos notes',
    Library_empty_subjects:
      'Aucun sujet disponible. Veuillez mettre à jour vos paramètres en cliquant sur le crayon ci-dessus!',
    // LibraryBrowse
    LibraryBrowse_hideCovered: 'Cacher couvert',
    LibraryBrowse_mySubjects: 'Mes sujets',
    LibraryBrowse_browseCurriculum: 'Parcourir par cursus',
    LibraryBrowse_browseTopics: 'Parcourir par sujets',
    LibraryBrowse_info2: 'Collections organisées',
    LibraryBrowsePage_viewAll: 'Voir tout',
    LibraryBrowsePage_curriculum: 'Programme d’études',
    LibraryBrowsePage_noCollectionsFound:
      'Aucune collection trouvée pour le sujet sélectionné',
    LibraryCurriculum_description:
      'Ceci est une copie expliquant l’xploration du contenu en fonction des objectifs du programme d’tudes provincial',
    LibrarySearch_inputMoreChar: 'Veuillez saisir au moins 2 caractères',
    LibraryCollections_noResults: 'Désolé, aucun résultat pour ',
    LibrarySearch_loading: 'Tenez bon, nous chargeons vos résultats!',
    'LibrarySearchPage_advanced-search': 'Options de recherche avancée',
    LibrarySearchPage_popover1:
      '• Utilise le symbole <strong>+</strong> pour combiner des mots-clés; ex. : <em>Terre + population</em>',
    LibrarySearchPage_popover2:
      '• Utilise le symbole <strong>-</strong> pour exclure des mots-clés; ex. : <em>Terre - eau</em>',
    LibrarySearchPage_popover3:
      '• Utilise les guillemets <strong>«»</strong> pour chercher des expressions exactes; ex. : <em>« la Terre mère »</em>',
    LibrarySearchPage_searchHint: 'Recherche par mot-clé',
    LibrarySearchPage_outsideSubjects: 'Rechercher En Dehors De Mes Sujets',
    LibrarySearchPage_withinSubjects: 'Rechercher Dans Mes Sujets',
    LibrarySearchResults_moreGeneral: 'Essayez des mots clés plus généraux.',
    LibrarySearchResults_removeFilters:
      'Supprimez les filtres pour élargir votre recherche.',
    LibrarySearchResults_spelledCorrectly:
      'Assure toi que tous les mots sont épelés correctement.',
    LibrarySearchResults_nothingFits:
      'Désolé, mais rien ne correspond à cette description.',
    LibrarySearchTags_results: 'Résultat',
    LibrarySearchTags_searching: 'Recherche...',
    LicenseHeader_administration: 'Administration des licences',
    LicenseHeader_plan: 'Le plan Edwin: ',
    LicenseHeader_plans: 'Les plans d’Edwin',
    LicenseHeader_termNotAvail: 'Terme non disponible',
    LicenseTutorial_switchPlans: 'Basculez entre vos forfaits',
    LicenseTutorial_inviteNew: 'Inviter de nouveaux utilisateurs',
    LicenseTutorial_applyFilters: 'Appliquer des filtres',
    LicenseTutorial_performOptions:
      'Une fois que vous avez sélectionné les utilisateurs, effectuez l’une de ces options',
    LicenseTutorial_searchUsers: 'Rechercher des utilisateurs par mot-clé',
    LicenseTutorial_moreHelp: 'Besoin d’aide?',
    LicenseCSV_upload: 'Téléchargez un fichier CSV depuis votre ordinateur',
    LicenseRegister_error:
      'Nous avons rencontré une erreur lors de l’invitation d’un ou plusieurs utilisateurs.',
    LicenseRegister_invalidEmail:
      'Ce n’est pas un format de courrier électronique valide.',
    LicenseRegister_invalidEmails:
      'Ce ne sont pas des formats de courrier électronique valides.',
    LicenseRegister_noEmails:
      'Veuillez saisir au moins une adresse e-mail valide.',
    LicenseDeactivate_error:
      'Nous avons rencontré une erreur lors de la désactivation d’un ou plusieurs utilisateurs.',
    LicenseDeactivate_thankYou:
      'Merci d’avoir soumis cette demande. Nous examinerons et ferons un suivi avec vous dans les plus brefs délais.',
    LicenseDeactivate_success:
      'Le(s) compte(s) ont été désactivés. Ces utilisateurs ne pourront plus se connecter à Edwin.',
    LicenseDeactivate_reason:
      'Veuillez choisir la raison de la désactivation :',
    LicenseDeactivate_user:
      'Avertissement! Vous êtes sur le point de désactiver des utilisateurs appartenant à différentes écoles.',
    LicenseDeactivate_reasonPlaceholder:
      'Veuillez expliquer la raison de cette demande. Une fois soumise, nous examinerons la demande et ferons un suivi avec vous.',
    LicenseDeactivate_differentSchool: 'L’utilisateur n’est plus à l’école',
    LicenseDeactivate_mistake:
      'Activé par erreur ou par un utilisateur inconnu',
    LicenseDeactivate_requested: 'Désactivation de l’utilisateur demandée',
    LicenseDeactivate_users: 'Désactiver le(s) utilisateur(s)',
    LicenseTable_none: 'Aucun',
    LicenseTable_all_grades: 'Toutes les notes',
    LicenseTable_all_roles: 'Tous les rôles',
    LicenseTable_all_locations: 'Tous les emplacements',
    LicenseTable_email: 'E-mail',
    LicenseTable_firstName: 'Prénom',
    LicenseTable_lastName: 'Nom de famille',
    LicenseTable_grades: 'Note(s)',
    LicenseTable_role: 'Rôle',
    LicenseTable_location: 'Emplacement',
    LicenseTable_lastSeen: 'Dernière Visite',
    LicenseTable_filterBy: 'Filtrer par',
    LicenseTable_results: 'Résultats pour:',
    LicenseActions_export: 'Exporter',
    LicenseActions_deactivate: 'Désactiver',
    LicenseActions_resend: 'Renvoyer l’invitation',
    LicenseActions_changeRole: 'Changer de rôle',
    LicenseActions_changeLocation: 'Changer de lieu',
    LicenseResend_alreadyRegistered:
      'Les utilisateurs sélectionnés sont déjà enregistrés.',
    LicenseResend_invitesSent: 'Les invitations ont été envoyées!',
    LicenseResend_resend: 'Renvoyer L’invitation',
    LicenseResend_confirmInvite:
      'Êtes-vous sûr de vouloir renvoyer un e-mail d’invitation à ces utilisateurs?',
    LicenseResend_yesResend: 'Oui, renvoyer',
    LicenseChangeRole_studentError:
      'Désolé, mais vous ne pouvez pas attribuer un rôle d’administrateur aux étudiants. Si vous devez le faire, veuillez contacter le support client Edwin.',
    LicenseChangeRole_changeRole: 'Changer de rôle',
    LicenseChangeRole_changeLocation: 'Changer de lieu',
    LicenseChangeRole_yesChangeRole: 'Oui, change de rôle',
    LicenseChangeRole_yesChangeLocation: 'Oui, change de rôle',
    LicenseChangeRole_confirmChange:
      'Êtes-vous sûr de vouloir modifier le rôle de ces utilisateurs sélectionnés?',
    LicenseChangeLocation_confirmChange:
      'Êtes-vous sûr de vouloir modifier l’emplacement de ces utilisateurs sélectionnés?',
    LicenseChangeRole_selectionText:
      'Modifiez le rôle des utilisateurs sélectionnés pour:',
    LicenseChangeLocation_selectionText:
      'Modifiez l’emplacement des utilisateurs sélectionnés pour:',
    LicenseChangeRole_success:
      'Le rôle des utilisateurs sélectionnés a été modifié',
    LicenseChangeRole_selectRole: 'Sélectionnez un rôle',
    LicenseChangeLocation_selectLocation: 'Sélectionnez l’emplacement',
    LicenseChangeRole_error:
      'Nous avons rencontré une erreur lors de la modification d’un ou plusieurs rôles d’utilisateur.',
    LicenseExport_allUsers: 'Tous Les Utilisateurs',
    LicenseExport_filteredUsers: 'Utilisateurs Filtrés',
    LicenseExport_selectedUsers: 'Utilisateurs Sélectionnés',
    LicenseExport_exportUsers: 'Exporter Les Utilisateurs',
    LicenseExport_who: 'Qui souhaiteriez-vous exporter?',
    LicenseKeyword_placeholder: 'Recherche par mots-clés',
    LicenseKeyword_twoChars: 'Veuillez saisir au moins 2 caractères',
    LicenseNoResults_title: 'Aucun résultat trouvé pour ',
    LicenseNoResults_desc1:
      'Assure toi que tous les mots sont épelés correctement.',
    LicenseNoResults_desc2: 'Essayez des mots clés plus généraux.',
    LicenseNoResults_desc3:
      'Supprimez les filtres pour élargir votre recherche.',
    License_helpCentre: 'Visitez notre centre d’ide',
    License_fileContains: 'Ce fichier contient',
    License_fileContains2: 'e-mails, mais vous n’avez que',
    License_fileContains3: 'les licences sont disponibles',
    License_fileContains4:
      'Veuillez télécharger à nouveau un CSV avec le montant correct.',
    License_UTF8formatted:
      'Le fichier CSV doit être au format UTF-8 et ne contenir que les adresses e-mail des enseignants.',
    License_moreInstructions:
      'Pour plus d’instructions, veuillez consulter ce tutoriel dans notre .',
    License_mailBoxAttached:
      'Ces adresses e-mail sont-elles associées à une boîte aux lettres ? Si oui, souhaitez-vous que nous vous envoyions des invitations avec des instructions pour vous connecter ?',
    License_yesSend: 'Oui, envoyer un e-mail',
    License_noThanks: 'Non merci',
    License_HelpCentre: 'centre d’ide.',
    LicenseStudent_licenses: 'Licences élèves',
    LicenseTeacher_invite: 'Inviter des enseignants',
    LicenseTeacher_currentPlan: 'Plan actuel:',
    LicenseTeacher_enterEmail: 'Entrer l’adresse e-mail',
    LicenseTeacher_inviteTeachers: 'Inviter des utilisateurs enseignants',
    LicenseTeacher_Licenses: 'licences d’enseignant',
    LicenseTeacher_UploadCSV: 'Télécharger un CSV',
    LicenseTeacher_invitationsSent: 'Les invitations ont été envoyées!',
    LicenseTeacher_accountsActive:
      'Ces comptes Enseignants sont désormais actifs.',
    LicenseTeacher_accountsActive2:
      'Ces comptes enseignants sont désormais actifs. Ils peuvent se connecter immédiatement à Edwin en utilisant le lien et les instructions dans l’e-mail d’invitation. Les enseignants doivent vérifier leurs boîtes de réception, ou peut-être leur dossier de courrier indésirable maintenant.',
    LicenseTeacher_accountsActive3: 'Merci d’avoir agrandi la famille Edwin!',
    LicenseTeacher_accountsActiveLarge:
      'Vos invitations sont en cours de traitement! l’ctivation de tous les comptes d’nseignants peut prendre quelques minutes.',
    LicenseAdmin_Licenses: `Licences d'administrateur`,
    LicenseAdmin_invite: 'Inviter des administrateurs',
    LicenseAdmin_inviteUsers: 'Inviter des utilisateurs administrateurs',
    LicenseAdmin_licensesAvail: 'licences d’administrateur disponibles',
    LicenseAdmin_separateEmails:
      'Séparez plusieurs adresses e-mail par une virgule',
    LicenseAdmin_onceSubmitted1:
      'Une fois soumis, nous enverrons un e-mail de vérification.',
    LicenseAdmin_onceSubmitted2:
      'Les administrateurs doivent cliquer sur le lien dans l’e-mail pour terminer l’enregistrement de ces comptes.',
    LicenseAdmin_onceSubmitted3:
      'Veuillez vous assurer que les comptes répertoriés peuvent recevoir des e-mails.',
    LicenseAdmin_onceVerified:
      'Une fois vérifiés, ils pourront se connecter à Edwin.',
    LicenseAdmin_onceVerified2:
      'Dans l’e-mail d’invitation, nous avons inclus un lien et des instructions sur la façon de vérifier leur compte. Les administrateurs doivent vérifier leurs boîtes de réception et peut-être leur dossier de courrier indésirable pour trouver cet e-mail.',
    LicenseAdmin_onceVerified3: 'Merci d’avoir agrandi la famille Edwin!',
    LicenseTeacher_separateEmails:
      'Séparez plusieurs adresses e-mail par une virgule',
    LicenseTeacher_invitationsSpreadsheet:
      'Vous souhaitez inviter un grand nombre ’enseignants à partir d’une feuille de calcul?',
    LicenseTeacher_teacherAccountsActive: 'Comptes enseignants actifs',
    LicenseTeacher_letThemKnow:
      'Veuillez leur faire savoir qu’ils peuvent commencer à utiliser Edwin. Besoin d’ide pour passer le mot ? Téléchargez un dépliant avec tous les détails.',
    LicenseTeacher_gettingWordOut: 'Besoin d’ide pour passer le mot?',
    LicenseTeacher_downloadDetailsFlyer:
      'Téléchargez un dépliant avec tous les détailsTéléchargez un dépliant avec tous les détails',
    ConfirmModal_confirmDelete:
      'Voulez-vous vraiment supprimer définitivement cette collection?',
    ConfirmModal_confirmDeleteItem:
      'Êtes-vous sûr de vouloir supprimer cet élément de votre copie de cette collection?',
    ConfirmModal_confirmDeleteScreenshot:
      'Êtes-vous sûr de vouloir supprimer définitivement cet élément?',
    ConfirmModal_teacherOnly:
      'Ceci est un article réservé aux enseignants<br></br>Êtes-vous sur de vouloir continuer?',
    // MathTools
    FractionStrips_title: 'Bandes de fractions ',
    FractionStrips_descTop: 'Glisser-déposer des bandes de fractions ici!',
    FractionStrips_descBottom:
      'Placer des fractions côte à côte pour les comparer.',
    FractionStrips_infoDragRows:
      'Cliquer ici et maintenir le bouton enfoncé pour faire glisser la rangée entière.',
    FractionStrips_infoDragStrips:
      'Faire glisser les bandes à partir du tiroir pour les disposer dans l’espace de travail.',
    FractionStrips_click:
      'Cliquez sur les bandes pour les disposer au hasard dans l’space de travail.',
    FractionStrips_infoTrash:
      'Faire glisser des bandes individuelles dans cette zone pour les supprimer',
    InteractiveTools_whiteboard: 'Tableau blanc',
    MathTools_Instruction_Draw: 'Dessinez et effacez sur l’space de travail.',
    MathTools_screenshot_desc:
      'Enregistrer une capture d’cran de l’space de travail.',
    MathTools_Instruction_Resize:
      '<span>Redimensionner</span><span>la fenêtre.</span>',
    MathTools_Instruction_Reset: 'Réinitialiser l’space de travail.',
    MathTools_Instruction_ResetSpinner: 'Réinitialisez le compteur.',
    MathTools_Instruction_Delete_Block:
      '<span>Faites glisser les blocs</span><span>ici pour supprimer.</span>',
    MathTools_Instruction_Delete_Tiles:
      '<span>Faites glisser les tuiles</span><span>ici pour supprimer.</span>',
    MathTools_Instruction_Delete_Chips:
      '<span>Faites glisser les jetons</span><span>ici pour supprimer.</span>',
    MathTools_Instruction_Delete_Items:
      '<span>Faites glisser les articles</span><span>ici pour supprimer.</span>',
    MathTools_fractionStrips: 'Bandes fractionnaires',
    MathTools_patternBlocks: 'Blocs de motif',
    MathTools_patternBlocks_Empty_Title:
      'Faites glisser et déposez des blocs de motifs ici!',
    MathTools_patternBlocks_Empty_Description:
      'Utilisez des blocs pour explorer les fractions, la géométrie et les motifs.',
    MathTools_patternBlocks_Instruction_Rotate:
      '<span>Appuyez sur un bloc</span><span>pour faire pivoter.</span>',
    MathTools_patternBlocks_Instruction_Box:
      'Faites glisser les blocs du tiroir pour les organiser dans l’space de travail.',
    MathTools_patternBlocks_Instruction_Click:
      'Cliquez sur les blocs pour les organiser de manière aléatoire dans l’space de travail.',
    MathTools_patternBlocks_Instruction_Background:
      'Afficher/Masquer l’rrière-plan.',
    MathTools_algebraTiles: 'Tuiles d’lgèbre',
    MathTools_algebraTiles_Empty_Title:
      'Glissez et déposez les carreaux d’lgèbre ici!',
    MathTools_algebraTiles_Empty_Description:
      'Utilisez des tuiles pour représenter les variables et les constantes.',
    MathTools_algebraTiles_Instruction_Rotate:
      '<span>Appuyez sur une tuile</span><span>pour faire pivoter.</span>',
    MathTools_algebraTiles_Instruction_Box:
      'Faites glisser les tuiles du tiroir pour les disposer dans l’space de travail. Vous pouvez également faire glisser un signe égal.',
    MathTools_algebraTiles_Instruction_Background: 'Afficher/masquer le cadre.',
    MathTools_algebraTiles_Instruction_Click:
      'Cliquez sur les tuiles pour les disposer de manière aléatoire dans l’space de travail.',
    MathTools_integerChips: 'Puces entières',
    MathTools_integerChips_Instruction_Box:
      'Faites glisser des puces entières individuelles ou des paires nulles du tiroir pour les organiser dans l’space de travail. Vous pouvez également faire glisser les signes d’pérations ici.',
    MathTools_integerChips_Rotate:
      'Appuyez sur une paire zéro pour faire pivoter.',
    MathTools_integerChips_Instruction_Click:
      'Cliquez sur les puces pour les disposer de manière aléatoire dans l’space de travail.',
    MathTools_baseTenBlocks: 'Blocs de base dix',
    MathTools_baseTenBlocks_Instruction_Box:
      'Faites glisser les blocs du tiroir pour les organiser dans l’space de travail. Vous pouvez également faire glisser les signes d’pérations ici.',
    MathTools_barModel: 'Bar Model',
    MathTools_barModel_addLabel: 'Ajoutez une étiquette à vos bars.',
    MathTools_barModel_click:
      'Cliquez sur les barres, les crochets et les lignes pour les disposer de manière aléatoire dans l’space de travail.',
    MathTools_barModel_resize: 'Faites glisser pour redimensionner.',
    MathTools_barModel_description:
      'Faites glisser les barres depuis le tiroir pour les organiser et les redimensionner dans l’space de travail. Vous pouvez également faire glisser des crochets et des lignes ici. ' +
      'Vous pouvez placer des étiquettes au-dessus de vos barres ou à côté d’lles dans l’space de travail.',
    MathTools_Instruction_Scroll:
      '<span>Faites défiler vers le bas pour</span><span>des tuiles supplémentaires.</span>',
    MathTools_Instruction_Drag:
      'Dessinez une boîte puis cliquez sur l’cône de la corbeille pour supprimer plusieurs objets',
    MathTools_geometryTools: 'Outils de géométrie',
    MathTools_graphingCalculator: 'Calculatrice graphique',
    MathTools_scientificCalculator: 'Calculatrice scientifique',
    MathTools_spinner_PossibleOutcomes: 'Résultats possibles',
    MathTools_spinner: 'Roue Qui Tourne',
    MathTools_spinner_NumberSpins: 'Nombre de tours',
    MathTools_spinner_ResetCounter: 'Réinitialiser le compteur',
    MathTools_spinner_Instruction_Spin:
      'Appuyez pour faire tourner la roulette.',
    MathTools_spinner_Instruction_PossibleOutcomes:
      'Sélectionnez le nombre de résultats possibles entre 2 et 10.',
    MathTools_spinner_Instruction_EnterLabel:
      'Entrez votre étiquette et choisissez la couleur.',
    MathTools_spinner_Instruction_TrackSpins:
      'Gardez une trace du nombre de tours.',
    MathTools_spinner_Instruction_ResetNumber:
      '(Le nombre sera réinitialisé à chaque fois que vous modifiez le nombre de résultats possibles).',
    Mathtool_screenshot_button: 'Capture d’cran',
    Mathtool_close: 'Fermer',
    Mathtool_instructions: 'Instructions',
    Mathtool_grid: 'Grille',
    Mathtool_frame: 'Cadre',
    'mathtools-drawing-menu-draw': 'Dessiner',
    'mathtools-drawing-menu-erase': 'Effacer',
    MathTool_resize: 'Redimensionner',
    Mathtool_clear: 'Réinitialiser',
    Mathtool_resetConfirmation:
      'Salut! Cela essuyera la toile, comme une nouvelle feuille de papier. Prêt à recommencer?',
    Mathtool_trash: 'Effacer',
    MathToolError: 'Erreur, veuillez réessayer',
    // Misc
    Authenticated_authorizing: 'Autorisation ...',
    Authenticated_loggedIn:
      'Vous devez être connecté à l’pplication Edwin pour afficher cette page.',
    Authenticated_unsavedWork:
      'Si vous avez du travail non enregistré, veuillez garder cette page ouverte et vous connecter à edwin dans une autre fenêtre',
    LoadingStatusBar_offlineMsg:
      'Vous êtes actuellement hors ligne. Les données peuvent être périmées.',
    // NavMenu
    NavMenu_home: 'Accueil',
    NavMenu_library: 'Bibliothèque',
    NavMenu_collections: 'Vos collections',
    NavMenu_dashboard: 'Vos tableaux de bord',
    NavMenu_help: 'Assistance',
    NavMenu_admin: 'Gestion des licences',
    NavMenu_notifications: 'Notifications',
    NavMenu_notificationToast: 'Vous avez une nouvelle notification',
    NavMenu_dashboardToast:
      'Créer un tableau de bord de classe et inviter des élèves',
    NavMenu_settings: 'Paramètres',
    NavMenuTools_SaveToYourNotes: 'Enregistrer dans vos notes',
    NavMenuTools_SaveToGoogleDrive: 'Enregistrer sur Google Drive',
    // Reader
    ReaderLoading_offline:
      'Il semble que tu sois hors ligne. Vérifie ta connexion.',
    ReaderLoading_longLoad: 'Le chargement est plus long qu’à l’habitude.',
    ReaderLoading_tryAgain: 'Essayer de nouveau',
    ReaderLoading_reload: 'Recharger',
    ReaderError_notFound: 'Oups! Cet élément est introuvable.',
    ReaderError_notFoundLibrary:
      'Oups! Cet élément est introuvable dans ta bibliothèque.',
    ReaderError_restricted: 'Contenu restreint',
    ReaderError_teacherOnlyInfo:
      'Vous n’avez pas accès à ce contenu,<br></br>veuillez contacter votre professeur pour y accéder.',
    ReaderError_desc1:
      'Pour accéder à cet élément, demande à ton enseignante ou à ton enseignant de te dire à quelle année d’études et à quel sujet il correspond et ajoute-le à ta bibliothèque à l’aide de',
    ReaderError_desc2: 'Bibliothèque',
    ReaderError_desc3: 'la page',
    ReaderControls_markAsCovered: 'Marquer comme couvert',
    ReaderControls_markAsCoveredDesc:
      'Marquez cette ressource comme couverte pour chaque classe et suivez la couverture de votre programme dans le tableau ',
    ReaderControls_markAsCoveredDescLink: 'de bord',
    ReaderControls_noDashboards: `Vous n'avez créé aucun tableau de bord de classe`,
    ReaderControls_noCurriculum: `Aucun programme d’études n’est associé à cette ressource.`,
    ReaderControls_navCollection: 'Collection:',
    ReaderControls_print: 'Imprimer',
    ReaderControls_whiteboard: 'Tableau blanc',
    ReaderControls_collections: 'Collections',
    ReaderControls_sourceInfo: 'Source d’information',
    ReaderControls_educatorTools: 'Outils pédagogiques',
    ReaderControls_selfCheck: 'Autoévaluation',
    ReaderControls_related: 'Ressources Associées',
    ReaderControls_notes: 'Notes',
    ReaderControls_search: 'Chercher',
    ReaderControls_sharableLink: 'Obtenir un hyperlien partageable',
    ReaderControls_duplicateCollection: 'Collection en double',
    ReaderControls_textSize: 'Modifier la taille de la police',
    ReaderControls_mathTools: 'Outils Interactifs',
    ReaderControls_TTS_play: 'Lire',
    ReaderControls_TTS_restart: 'Recommencer',
    ReaderControls_TTS_rewind: 'Reculer',
    ReaderControls_TTS_forward: 'Avancer',
    ReaderControls_TTS_pause: 'Pause',
    ReaderControls_TTS_resume: 'Reprendre',
    ReaderControls_TTS_voiceSettings: 'Paramètres vocaux',
    ReaderControls_TTS_speed: 'Vitesse',
    ReaderPage_maximumImages:
      'Le nombre maximum d’mages de capture d’cran est de 25, veuillez en supprimer une de vos notes pour en enregistrer une autre.',
    ReaderPage_saveToNotes: 'Où voulez-vous enregistrer votre capture d’cran ?',
    ReaderPage_download: 'Télécharger',
    ReaderPage_downloading: 'Téléchargement', // note this is displayed dynamically in ScreenshotOptions.tsx
    AssignModal_title: 'Titre*',
    AssignModal_desc: 'Description',
    Citation_sourceInfo: 'Source d’information',
    Citation_accessInfo:
      '<b>Renseignements sur l’accès :</b> Obtenus d’ <i>Edwin</i> le',
    Citation_authors: '<b>Auteur(e)(s) : </b>',
    Citation_title: '<b>Titre : </b>',
    Citation_publisher: '<b>Éditeur : </b>',
    Citation_date: '<b>Date : </b>',
    Citation_website: '<b>Nom du site Web : </b> Edwin',
    CollectionsDropDown_add: 'Ajouter à la collection',
    CollectionsDropDown_added: 'Ajouté!',
    CollectionsDropDown_new: 'Nom de la nouvelle collection',
    CollectionsDropDown_create: 'Créer une collection',
    CrossLinkedBackButton_return: 'Revenir à :',
    EducatorTools_educatorTools: 'Outils pédagogiques',
    EducatorTools_offline1: 'Les outils pédagogiques sont indisponibles.',
    EducatorTools_offline2:
      'Vous devez être en ligne pour utiliser cette fonctionnalité. Veuillez vérifier votre connexion.',
    EducatorTools_teacherSupport: 'Soutien au personnel enseignant',
    EducatorTools_activities: 'Activités et outils',
    EducatorTools_development: 'Perfectionnement professionnel',
    EducatorTools_teachingNotesToggleOn: 'Marche',
    EducatorTools_teachingNotesToggleOff: 'Arrêt',
    EducatorTools_teachingNotesToggle: 'Remarques de l’enseignant',
    EducatorTools_presentationMode: 'Mode Présentation',
    WhiteboardPanel_title: 'Tableau blanc',
    NotesPanel_notes: 'Notes',
    NotesPanel_deleteImg: 'Supprimer l’mage',
    NotesPanel_takeNotes:
      'Prenez des notes ici. Vos notes et faits saillants seront automatiquement enregistrés.',
    NotesPanel_lastEdit: 'Modifié',
    NotesPanel_saveToDocs: 'Sauvegarder dans Documents',
    NotesPanel_highlights: 'Points forts',
    NotesPanel_sortByColor: 'Trier par couleur',
    NotesPanel_download: 'Télécharger des notes et des faits saillants',
    NotesPanel_emptyNote: 'Impossible de sauvegarder une note vide',
    NotesPanel_tryAgain: 'Veuillez réessayer.',
    NotesPanel_infoDropdown1:
      'Il semble que vous ayez déjà un document intitulé',
    NotesPanel_infoDropdown2: 'dans votre Google Drive.',
    NotesPanel_infoDropdown3:
      'Voulez-vous le remplacer par cette nouvelle version?',
    NotesPanel_cookies_disabled:
      'Veuillez activer les cookies et actualiser pour qu’Edwin exporte une note',
    NotesPage_sortByDate: 'Trier par date',
    NotesPage_sortByTitle: 'Trier par titre',
    PrintInfo_info:
      'L’impression ou l’adaptation du matériel d’Edwin est permise uniquement pour répondre aux besoins d’utilisateurs avec handicaps ou déficiences.',
    PrintInfo_msg:
      'J’imprime ce matériel pour me conformer à une politique d’accessibilité.',
    PrintInfo_agree: 'Je suis d’accord.',
    RelatedLearningObjects_related: 'Ressources Associées',
    RelatedLearningObjects_moreHelp: 'Plus d’ide',
    RelatedLearningObjects_moreOnTopic: 'En Savoir Plus sur ce Sujet',
    SearchPanel_search: 'Recherche',
    SearchPanel_searchLower: 'recherche',
    SearchPanel_searchInput: 'chercher dans cette page',
    SearchPanel_toc: 'Table des matières',
    SearchPanel_infoButton: 'Plus d’information',
    SearchPanel_noResults: 'Aucun résultat trouvé',
    SearchPanel_result1: 'résultat',
    SearchPanel_result2: 'trouvé',
    SelfAssessment_selfCheck: 'Autoévaluation',
    SelfAssessment_empty:
      'Aucune question d’autoévaluation pour cet objet d’apprentissage',
    SelfAssessment_choiceHigh: 'Compris!',
    SelfAssessment_choiceMed: 'On y est presque.',
    SelfAssessment_choiceLow: 'Travail toujours en cours',
    SelfAssessment_seeResponses: 'Voir les réponses',
    // Settings
    SettingsResources_proceed: 'procéder',
    SettingsChangeInfo_header: 'Modifier vos informations',
    SettingsChangeInfo_name: 'Nom',
    SettingsChangeInfo_givenName: 'Prénom',
    SettingsChangeInfo_familyName: 'Nom de famille',
    SettingsChangeInfo_teacherEmail: 'Email alternatif',
    SettingsChangeInfo_school: 'École',
    SettingsChangeInfo_teacherType: 'Comment allez-vous utiliser Edwin:',
    SettingsChangeInfo_requiredError:
      'S’il vous plait entrez votre nom entier.  ',
    SettingsChangeInfo_validationError:
      'Je suis désolé, notre système ne peut pas accepter ce nom',
    SettingsChangeInfo_emailRegexError:
      'S’il vous plaît, mettez une adresse email valide',
    // SettingsPage
    SettingsPage_title: 'Paramètres',
    SettingsPage_language: 'Langue',
    SettingsPage_NewTeacherModal_Enable:
      'Afficher le tutoriel sur la page d’ccueil',
    SettingsPage_NewTeacherModal_Disable:
      'Masquer le tutoriel sur la page d’ccueil',
    UserProfile_email: 'Email:',
    UserProfile_teacherEmail: 'Email alternatif:',
    UserProfile_account: 'Compte:',
    UserProfile_school: 'École:',
    UserProfile_type: 'Comment allez-vous utiliser Edwin:',
    UserProfile_signOut: 'Déconnexion',
    UserProfile_edit: 'Modifier vos informations',
    UserProfile_signOutConfirm:
      'Veux-tu vraiment te<br></br> déconnecter d’Edwin?',
    UserProfile_footnote: '*Votre e-mail principal ne peut pas être modifié',
    // Home New Teacher Modal
    HomeNewTeacherModal_Heading: 'Bonjour!',
    HomeNewTeacherModal_Desc:
      'Suivez ces étapes pour que vous et votre classe soyez opérationnels avec Edwin cette année.',
    HomeNewTeacherModal_Quickstart: 'Guide pour',
    HomeNewTeacherModal_Quickstart_Teachers: 'Eseignants',
    HomeNewTeacherModal_Quickstart_Students: 'Élèves',
    HomeNewTeacherModal_Invite1: 'Invitez Vos',
    HomeNewTeacherModal_Invite2: 'Élèves',
    HomeNewTeacherModal_Replay: 'Rejouer',
    HomeNewTeacherModal_Dismiss: 'Rappelez-moi plus tard',
    HomeNewTeacherModal_DontShowAgain: 'Ne plus afficher ça',
  },
}
