import React, { useEffect, lazy, Suspense, useRef } from 'react'
import { Redirect, Route, useRouteMatch, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  BackgroundImage,
  fadeIn,
  PRIMARY_LIGHT,
  ResetPasswordModal,
  WHITE_COLOR,
} from '@edwin-edu/ui-web-components'
import {
  HOME_PATH,
  LIBRARY_PATH,
  COLLECTIONS_PATH,
  DASHBOARD_PATH,
  ADMIN_PATH,
  SETTINGS_USER_SETTINGS,
  NO_MENU_PATHS,
  READER_LO_PATH,
  NAV_MENU_WIDTH,
  SITE_MIN_HEIGHT,
  RETRY_PATH,
  RESET_LINK_EXPIRED_PATH,
  LIBRARY_ERROR_PATH,
  MATH_TOOLS_PATH,
  NOTES_PATH,
  SINGLE_COLLECTION_PATH,
  ONBOARDING_PATH,
} from '../constants'
import NavMenu from './navmenu/NavMenu'
import { initTabNavigation } from '../utils/navigation'
import { LoadingPage } from './LoadingPage'
import { selectAppRootMinWidth, selectUserEmail } from '../selectors'
import CheckVersion from '../components/CheckVersion'
import { sendPasswordResetEmail } from '../services/authentication'
import { useActions } from '../hooks/useActions'
import { push } from 'connected-react-router'
import 'react-loading-skeleton/dist/skeleton.css'
import '@edwin-edu/ui/index.css'
import { SkeletonTheme } from 'react-loading-skeleton'
import { OnboardingCheck } from './onboarding/OnboardingCheck'

/* Loadable Route Components */
const LazyReaderPage = lazy(() =>
  import(/* webpackPrefetch: true */ './reader/ReaderPage'),
)
const LazyCollectionsPage = lazy(() =>
  import(/* webpackPrefetch: true */ './collections/CollectionsGrid'),
)
const LazyCollectionDetailPage = lazy(() =>
  import(/* webpackPrefetch: true */ './collections/CollectionDetailPage'),
)
const LazyLibraryPage = lazy(() =>
  import(/* webpackPrefetch: true */ './library/LibraryPage'),
)
const LazyHomePage = lazy(() =>
  import(/* webpackPrefetch: true */ './home/HomePage'),
)
const LazyMathToolsPage = lazy(() =>
  import(/* webpackPrefetch: true */ './mathtools/MathToolsPage'),
)
const LazyNotesPage = lazy(() =>
  import(/* webpackPrefetch: true */ './notes/NotesPage'),
)
const LazySettingsPage = lazy(() =>
  import(/* webpackPrefetch: true */ './settings/SettingsPage'),
)
const LazyDashboardPage = lazy(() =>
  import(/* webpackPrefetch: true */ './dashboard/routes'),
)
const LazyAdminPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../containers/admin/AdminPage'),
)
const LazyLibraryErrorPage = lazy(() =>
  import(/* webpackPrefetch: true */ './error/LibraryErrorPage'),
)
const LazyOnboardingPage = lazy(() =>
  import(/* webpackPrefetch: true */ './onboarding/OnboardingPage'),
)

export default function AppRoot() {
  const appRootMinWidth = useSelector(selectAppRootMinWidth)
  const email = useSelector(selectUserEmail)
  const navigateHome = useActions((path) => push('/'))
  const location = useLocation()
  const pagesContainerRef = useRef()

  useEffect(() => {
    if (pagesContainerRef?.current) {
      pagesContainerRef.current.scrollTop = 0
    }
  }, [location?.pathname])

  useEffect(() => {
    initTabNavigation()
  }, [])

  // Forces re-render of reader. Might be a better method for this
  const renderReader = (props) => {
    // eslint-disable-next-line react/prop-types
    return <LazyReaderPage key={'reader-' + props.match.params.id} {...props} />
  }

  // make global root scrollable for some pages (library home, home)
  const scrollable = useRouteMatch({
    path: `(${HOME_PATH}|${LIBRARY_PATH}|${NOTES_PATH}|${ADMIN_PATH}|${DASHBOARD_PATH})`,
    strict: true,
  })

  return (
    <>
      <SkeletonTheme
        baseColor={PRIMARY_LIGHT}
        highlightColor={WHITE_COLOR}
        borderRadius="1em"
      >
        {/* eslint-disable-next-line react/no-children-prop */}
        <SiteContainer minWidth={appRootMinWidth}>
          <BackgroundImage />
          <OnboardingCheck>
            {/* eslint-disable-next-line react/no-children-prop */}
            <Route
              path={NO_MENU_PATHS}
              children={({ match }) => (
                <>
                  {!match && <NavMenu />}
                  <PagesContainer
                    id="main"
                    noNav={!!match}
                    scrollable={scrollable}
                    ref={pagesContainerRef}
                  >
                    <Suspense fallback={<LoadingPage />}>
                      <>
                        <CheckVersion />
                        <Route
                          path={RESET_LINK_EXPIRED_PATH}
                          render={() => (
                            <ResetPasswordModal
                              goBack={navigateHome}
                              onSubmit={sendPasswordResetEmail}
                              storedEmail={email}
                              linkExpired
                            />
                          )}
                        />
                        <Route
                          exact
                          path={RETRY_PATH}
                          render={() => <Redirect to={HOME_PATH} />}
                        />
                        <Route
                          exact
                          path={HOME_PATH}
                          render={(props) => <LazyHomePage {...props} />}
                        />
                        <Route
                          exact
                          path={MATH_TOOLS_PATH}
                          render={(props) => <LazyMathToolsPage {...props} />}
                        />
                        <Route
                          exact
                          path={NOTES_PATH}
                          render={(props) => <LazyNotesPage {...props} />}
                        />
                        <Route
                          path={LIBRARY_PATH}
                          render={(props) => <LazyLibraryPage {...props} />}
                        />
                        <Route
                          exact
                          path={READER_LO_PATH}
                          render={renderReader}
                        />
                        <Route
                          path={COLLECTIONS_PATH}
                          exact
                          render={(props) => <LazyCollectionsPage {...props} />}
                        />
                        <Route
                          path={SINGLE_COLLECTION_PATH}
                          exact
                          render={(props) => (
                            <LazyCollectionDetailPage
                              {...props}
                              // eslint-disable-next-line react/prop-types
                              key={props?.match?.params?.collectionId}
                            />
                          )}
                        />
                        <Route
                          path={DASHBOARD_PATH}
                          render={(props) => <LazyDashboardPage {...props} />}
                        />
                        <Route
                          path={SETTINGS_USER_SETTINGS}
                          render={(props) => <LazySettingsPage {...props} />}
                        />
                        <Route
                          path={ADMIN_PATH}
                          render={() => <LazyAdminPage />}
                        />
                        <Route
                          path={LIBRARY_ERROR_PATH}
                          render={(props) => (
                            <LazyLibraryErrorPage {...props} />
                          )}
                        />
                        <Route
                          path={ONBOARDING_PATH}
                          render={(props) => <LazyOnboardingPage {...props} />}
                        />
                      </>
                    </Suspense>
                  </PagesContainer>
                </>
              )}
            />
          </OnboardingCheck>
        </SiteContainer>
      </SkeletonTheme>
    </>
  )
}

const SiteContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  min-height: ${SITE_MIN_HEIGHT};
  min-width: ${({ minWidth }) => minWidth}px;
  align-items: stretch;
  overflow: hidden;
  animation: 200ms ease-out ${fadeIn};
  z-index: 0;
`
const PagesContainer = styled.div`
  position: relative;
  width: calc(100% - ${({ noNav }) => (noNav ? '0px' : NAV_MENU_WIDTH)});
  height: 100%;
  overflow-y: ${({ scrollable }) => (scrollable ? 'auto' : 'none')};
  @media (max-width: 1088px) {
    overflow-x: hidden;
  }
`
