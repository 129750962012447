import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useScript } from '../hooks/useScript'
import { config } from '../services/config'
import * as PropTypes from 'prop-types'

const MathJaxContext = createContext({
  loaded: false,
  error: false,
  MathJax: null,
})

export function MathJaxProvider({ children }) {
  const [loaded, error] = useScript(config.get('app.services.content.mathjax'))

  useEffect(() => {
    if (loaded && window.MathJax) {
      window.MathJax.Hub.Config({
        showMathMenu: false,
        showMathMenuMSIE: false,
        'fast-preview': { disabled: true },
        tex2jax: { preview: 'none' },
        config: ['MMLorHTML.js'],
        jax: [
          'input/MathML',
          'output/HTML-CSS',
          'output/NativeMML',
          'output/PreviewHTML',
        ],
        extensions: [
          'mml2jax.js',
          'MathMenu.js',
          'MathZoom.js',
          'AssistiveMML.js',
          'a11y/accessibility-menu.js',
        ],
      })
      window.logger.log('MathJax loaded & configured')
    } else if (error) {
      window.logger.warn('Error loading MathJax')
    }
  }, [loaded, error])

  return (
    <MathJaxContext.Provider value={{ loaded, error, MathJax: window.MathJax }}>
      {children}
    </MathJaxContext.Provider>
  )
}
MathJaxProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Right now we only support mathML (It's what the Edwin Content Team uses)
export function MathJaxNode({ mathML }) {
  const { loaded, MathJax } = useContext(MathJaxContext)
  const el = useRef(null)

  useEffect(() => {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, el.current])
  }, [loaded, mathML])

  return <span ref={el} dangerouslySetInnerHTML={{ __html: mathML }} />
}

MathJaxNode.propTypes = {
  mathML: PropTypes.node,
}
