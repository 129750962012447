import React, {
  FC,
  ChangeEventHandler,
  KeyboardEventHandler,
  PropsWithChildren,
} from 'react'
import { useIntl } from 'react-intl'
import { Tooltip, TooltipProps } from '@edwin-edu/ui'
import {
  Input,
  TextArea,
  ClickButton,
  ClickButtonProps,
} from '@edwin-edu/ui-web-components'
import Link from '../../components/Link'
import { ExternalLink } from '../../components/ExternalLink'
import { SimpleToolTip } from '../SimpleToolTip'

interface Props extends ClickButtonProps {
  id: string
  className?: string
  role?: string
  type?: string
}

export const FormattedClickButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { formatMessage } = useIntl()
    const { id, tooltipPosition, tooltipMargin } = props
    const hasToolTip =
      tooltipPosition !== undefined || tooltipMargin !== undefined
    return (
      <ClickButton
        // @ts-ignore todo
        ref={ref}
        aria-label={formatMessage({ id })}
        tooltipText={hasToolTip ? formatMessage({ id }) : undefined}
        {...props}
      />
    )
  },
)

export const FormattedLink = (props: Props) => {
  const { formatMessage } = useIntl()
  const { id, tooltipPosition, tooltipMargin } = props
  const hasToolTip =
    tooltipPosition !== undefined || tooltipMargin !== undefined
  return (
    <Link
      aria-label={formatMessage({ id })}
      tooltipText={hasToolTip ? formatMessage({ id }) : undefined}
      title={formatMessage({ id })}
      {...props}
    />
  )
}

interface ExternalLinkProps {
  id: string
  href: string
  button?: boolean
  dispatch?: (e: Event) => void
  children: React.ReactNode
  onClick?: (e: Event) => void
}
export const FormattedExternalLink = (props: ExternalLinkProps) => {
  const { formatMessage } = useIntl()
  const { id } = props
  // @ts-ignore Update props
  return <ExternalLink aria-label={formatMessage({ id })} {...props} />
}

interface IdProps {
  id: string
  position?: string
}

export const FormattedTextArea = React.forwardRef<HTMLTextAreaElement, IdProps>(
  (props, ref) => {
    const { formatMessage } = useIntl()
    const { id } = props
    return <TextArea placeholder={formatMessage({ id })} ref={ref} {...props} />
  },
)

// uses ref in CollectionsDropDownMenu
export const FormattedTitleInput = React.forwardRef<
  HTMLInputElement,
  {
    id: string
    type: string
    value?: string // optional so that we can inject values from react-hook-form's register()
    onChange: ChangeEventHandler<HTMLInputElement>
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  }
>((props, ref) => {
  const { formatMessage } = useIntl()
  const { id } = props
  return <Input placeholder={formatMessage({ id })} ref={ref} {...props} />
})

export const FormattedSimpleToolTip: FC<PropsWithChildren<IdProps>> = (
  props,
) => {
  const { formatMessage } = useIntl()
  const { id } = props
  // @ts-ignore todo
  return <SimpleToolTip text={formatMessage({ id })} {...props} />
}

export const FormattedTooltip: FC<
  Omit<TooltipProps, 'label'> & { id: string; ref?: React.Ref<HTMLElement> }
> = (props) => {
  const { formatMessage } = useIntl()
  return <Tooltip label={formatMessage({ id: props.id })} {...props} />
}
