import React from 'react'
import PropTypes from 'prop-types'
import styles from './ErrorPage.module.scss'
import { Flex } from '@edwin-edu/ui'

const ErrorPage = ({ pageIconSVG, children }) => {
  return (
    <Flex className={styles.errorContainer}>
      <Flex className={styles.leftContainer}>{pageIconSVG}</Flex>
      <Flex className={styles.verticalDivider} />
      <Flex direction="column" className={styles.rightContainer}>
        {children}
      </Flex>
    </Flex>
  )
}

ErrorPage.propTypes = {
  pageIconSVG: PropTypes.object,
  children: PropTypes.node,
}

export default ErrorPage
